<template>
  <div>
    <div class="relative flex flex-col mx-auto max-w-142 py-14">
      <div class="px-8">
        <StepperBar
          :active="active"
          :steps="steps"
          modern
          @step-click="onStepClick"
        />
      </div>
      <router-view/>
    </div>
    <Portal to="Modal">
      <Modal v-model="resetModal">
        <div class="max-w-lg px-6 py-6">
          <div class="mb-4">
            <p v-html="$t('reallyWantToResetData')"/>
          </div>
          <div class="flex items-center">
            <Button class="w-full mr-2" @click="resetModal = false">{{ $t('cancel') }}</Button>
            <Button class="w-full ml-2" primary @click="reset">{{ $t('reset') }}</Button>
          </div>
        </div>
      </Modal>
    </Portal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      resetModal: false,
    };
  },

  computed: {
    selectedContract() {
      return this.$store.state.contract.selectedContract;
    },
    fees() {
      return this.$store.state.contract.fees;
    },
    steps() {
      return [
        { label: this.$t('contract_selection'), key: 'vertragswahl', route: 'contracts' },
        {
          label: this.$t('flat_rates'), key: 'pauschalen', route: 'flat-rates', hide: this.fees.length === 0,
        },
        { label: this.$t('starting_conditions'), key: 'startkonditionen', route: 'starting-conditions' },
        { label: this.$t('customer_data'), key: 'kundendaten', route: 'customer' },
        { label: this.$t('accounting'), key: 'buchung', route: 'accounting' },
        { label: this.$t('summary'), key: 'zusammenfassung', route: 'summary' },
      ];
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.active = route.meta.activeStep;
      },
    },
  },

  created() {
    this.getContractTemplates();
    if (this.$route.name !== 'contracts' && !this.selectedContract) {
      this.$router.push({ name: 'contracts' });
    }
  },

  methods: {
    getContractTemplates() {
      this.$store.dispatch('getContractTemplates');
    },
    close() {
      this.resetModal = true;
    },
    reset() {
      this.$store.commit('resetNewContract');
      this.$store.commit('setSearchResults', []);
      this.resetModal = false;
      this.$router.push({ name: 'contracts' });
    },
    onStepClick(index) {
      this.$router.push(this.steps[index].route);
    },
  },
};
</script>
